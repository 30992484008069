import React from 'react';
import "./aboutSection.css";
import Carousel from '../../Components/Carousel/Carousel'

const AboutSection = () => {

    return (
        <div className='about-section' id='about'>
            <div className='container about-text-container'>
                <div className='row'>
                    <div className='col-lg-5 col-md-4 col-sm-12 about-insurance '>
                        <h1>ABOUT <span>UBIQC</span></h1>
                    </div>
                    <div className='col-lg-7 col-md-8 col-sm-12  about-para'>
                        <p>Ubiqc-Insurance Wallet creates an Ecosystem of Insurance providers and PolicyHolders. This offers a platform for the Insurers to engage and retain their customers, whereas Customers have the delight of interfacing multiple Insurers / services through a single Mobile App. For customers/Policy Holders it cuts the clutter of multiple communication channels (Apps/Emails/SMS/Calls) for multiple policies. The diverse ecosystem offers an endless opportunity to the Insurers to expand their customer base.</p>
                    </div>
                </div>
            </div>
            <Carousel />
            
        </div>
    )
}

export default AboutSection