import React from 'react';
import UbiqcLogo from '../ubiqcLogo/UbiqcLogo'
import "./navbar.css";
import UseScrollPosition from '../../Hooks/UseScrollPosition'

const Header = () => {
    const classNames = ((...classes) => {
        return classes.filter(Boolean).join(' ');
    })
    const scrollPosition = UseScrollPosition();
    const onScrollIconColor = () => {
        let color;
        if (scrollPosition > 0) {
            color = "#000"
        } else {
            color = "#fff"
        }
        return color;
    }

    const iconColor = onScrollIconColor();
    
    const scrollTop = () =>{
        window.scrollTo({top: 1, behavior: 'smooth'});
     };

    
    return (
        <div className="container">
            <nav className={classNames(scrollPosition > 0 ? "navbar navbar-expand-lg navbar-light p-3 fixed-top navbar-bg-change" : "navbar navbar-expand-lg navbar-light p-3 fixed-top")} >
                <div className="container">
                    <a className="navbar-brand" href="#"> <UbiqcLogo color={iconColor}/></a>
                    <button onClick={scrollTop}  className={classNames("navbar-toggler collapsed d-flex d-lg-none flex-column justify-content-around")} type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" style={{border: "none !important"}} >
                        <span className="toggler-icon top-bar" style={{backgroundColor: iconColor}}></span>
                        <span className="toggler-icon middle-bar" style={{backgroundColor: iconColor}}></span>
                        <span className="toggler-icon bottom-bar" style={{backgroundColor: iconColor}}></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link" href="#about" style={{color: iconColor}}>About Us</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://blogs.ubiqc.com" style={{color: iconColor}}>Blog</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#features" style={{color: iconColor}}>Features</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#partners" style={{color: iconColor}}>Partners</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#getTheApp" style={{color: iconColor}}>Get the App</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#team" style={{color: iconColor}}>Team</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#contact" style={{color: iconColor}}>Contact</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Header