import React from 'react';
import "./partner.css";
import Data from "../../Data/data.json"

const Partners = () => {
    const [, , , partners] = Data;
    return (
        <div className='container' id='partner'>
            <section id="partners" className="partners">
                <h1 className='text-center'>Partners</h1>
                <div className="slider row" >
                    <ul className="slideContainer1 pt-4">
                        {
                            partners.partners.map((partner) => {
                                return (
                                    <li className="slideItem  col-lg-3" key={partner.id}>
                                        <img
                                            src={partner.image}
                                            alt={partner.name}
                                            className='partner-bg'
                                        />
                                    </li>
                                )
                            })
                        }

                    </ul>
                </div>
            </section>
        </div>
    )
}

export default Partners