
import React from 'react';
import "./gallery.css";
import { motion } from "framer-motion"
// import { InstagramEmbed, TwitterEmbed ,LinkedInEmbed} from 'react-social-media-embed';

const Gallery = () => {

    const container = {
        hidden: { opacity: 1, scale: 0 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.3,
                staggerChildren: 0.2
            }
        }
    };

    const item = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1
        }
    };
    return (
        <>
            <div className='container mt-5'>
                <h2 className='text-center p-5' style={{ color: "#ffdabf" }}>Social Musings</h2>

                <div className='row post social-gallery'>
                    <div className='col-lg-3 '>
                        <a href='https://www.instagram.com/p/Chv-qrlpUVi/?utm_source=ig_web_copy_link' target="blank"><img src='Assets/Images/gallery/social_media/instagram.PNG' className='img-thumbnail' alt='' /></a>
                    </div>

                    <div className='col-lg-3'>
                        <a href='https://m.facebook.com/story.php?story_fbid=pfbid02DTE39nxifa7m4o5AezcpAdGhVmQVZXDetkrS1y9i2iZWw9c6H94FqTCzAvbEfeeLl&id=100063926746721' target="blank"><img src='Assets/Images/gallery/social_media/Facebook.PNG' className='img-thumbnail' alt='' /></a>

                    </div>

                    <div className='col-lg-3'>
                        <a href='https://www.linkedin.com/feed/update/urn:li:activity:6978934307398520832/' target="blank"><img src='Assets/Images/gallery/social_media/Linkedin.PNG' className='img-thumbnail' alt='' /></a>

                    </div>
                    <div className='col-lg-3'>
                        <a href='https://twitter.com/Ubiqc1/status/1570633363895242753?s=20&t=mVPRpM_xXKrqcDnw1-WB4A' target="blank"><img src='Assets/Images/gallery/social_media/twitter.jpg' className='img-thumbnail' alt='' /></a>

                    </div>
                </div>

            </div>


            <motion.div className='container mt-5 ' variants={container}
                initial="hidden"
                animate="visible">
                <h2 className='text-center p-5' style={{ color: "#ffdabf" }}>Office Hues </h2>
                <div className='row office-hues'>
                    <div className='col-lg-6 col-sm-6'>
                        <motion.img
                            src="Assets/Images/gallery/office_hues/gallery-1.jpg"
                            className="w-100 shadow-1-strong rounded mb-4"
                            alt="Wintry Mountain Landscape"
                            variants={item}
                        />

                    </div>
                    <div className='col-lg-6 col-sm-6'>
                        <img
                            src="Assets/Images/gallery/office_hues/gallery-2.jpg"
                            className="w-100 shadow-1-strong rounded mb-4 img-fluid"
                            alt="Boat on Calm Water"
                            variants={item}
                        />
                    </div>
                </div>


            </motion.div>
        </>
    )
}

export default Gallery;