import Navbar from '../../Components/Navbar/Navbar';
import HeaderSection from '../../Components/Header-Section/HeaderSection';
import AboutSection from '../../Components/About/AboutSection';
import BrokerSection from '../../Components/BrokerSection/BrokerSection';
import Partners from '../../Components/Partners/Partners';
import BrokerContact from '../../Components/BrokerContact/BrokerContact';
import GetApp from '../../Components/GetApp/GetApp';
import Team from '../../Components/Team/Team';
import ContactUs from '../../Components/ContactUs/ContacUs';
import Footer from '../../Components/Footer/Footer';
import Gallery from '../../Components/Gallery/Gallery';


const Home = () => {
    return (
        <div className='ubiqc-app' >

            <Navbar />
            <HeaderSection />
            <AboutSection />
            <BrokerSection />
            <Partners />
            <BrokerContact />
            <GetApp />
            <Team />
            <Gallery />
            <ContactUs />
            <Footer />

        </div>
    )
}

export default Home