import React from 'react';
import "./carousel.css";
import Data from "../../Data/data.json";


const Carousel = () => {
    const [features, ,] = Data;
    return (
        <div className='container about-carousel' id='features' >
            <div id="carouselExampleCaptions" className="carousel slide carousel-fade" data-bs-ride="carousel" data-interval='10' data-bs-touch="false">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="5" aria-label="Slide 6"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="6" aria-label="Slide 7"></button>


                </div>
                <div className="carousel-inner mx-auto container-fluid">
                    <div className="carousel-item active">
                        <img src="Assets/Images/Features/1.png" className="d-block features-img ml-120" alt=''/>
                        <div className="carousel-caption d-md-block">
                            <h2>Multi-Brand Policy Dashboard</h2>
                        </div>
                    </div>
                    {
                        features.features.map((feature) => {
                            return (
                                <div className="carousel-item" key={feature.id}>
                                    <img src={feature.image} className="d-block features-img ml-120" alt="..." />
                                    <div className="carousel-caption d-md-block">
                                        <h2>{feature.description}</h2>

                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    )
}

export default Carousel