
import React from 'react'
import "./app.css"
import Home from "./Pages/home/Home"
const App = () => {
  return (
    <Home />
  )
}

export default App