import React from 'react';
import './headerSection.css'

const HeaderSection = () => {
    return (
        <div className='header-section '>
            <div className='container main-title'>

                <div className='main-heading'>
                    <h1>Insurance </h1>
                    <h1>Wallet <sup className='register-mark'>®</sup></h1>
                </div>
                <div className='main-paragraph'>
                    <p>Store & Manage your </p>
                    <p>Insurance Policies hassle free.</p>
                </div>
            </div>


        </div>
    )
}

export default HeaderSection