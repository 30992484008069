import React from 'react';
import "./getApp.css";


const GetApp = () => {
    return (
        <div className='container get-app pt-10' id='getTheApp' >
            <div className="row">
                <div className='col-lg-5'>
                    <img className='get-app-img' src="Assets/Images/Background/android-phone.png" />
                </div>
                <div className='col-lg-7'>
                    <div className='store'>
                        <h5>Download The UBIQC App today!</h5>
                        <div className=' download-btn'>
                            <img className='qr-code' src="Assets/Images/Background/google-qr-code.png" />
                            <a href='https://play.google.com/store/apps/details?id=com.ubiqc.android&hl=en_IN&gl=US' target='blank'><img className='store-btn' src="Assets/Images/Background/google-play-btn.svg" /></a>
                        </div>
                        <div className='download-btn custom-margin'>
                            <img className='qr-code' src="Assets/Images/Background/apple-qr-code.png" />
                            <a href='https://apps.apple.com/us/app/ubiqc/id1290070659' target='blank' ><img className='store-btn' src="Assets/Images/Background/apple-store-btn.svg" /></a>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    )
}

export default GetApp