import React from 'react'

const UbiqcLogo = ({color}) => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 width="100px" height="29px" viewBox="0 0 100 29" enableBackground="new 0 0 100 29">
<g>
	<rect x="82.072" y="24.428" fill="#F5907A" width="17.879" height="0.838"/>
	<path fill="#F5907A" d="M52.99,0.146c0.732,0,1.357,0.196,1.873,0.587c0.515,0.392,0.771,0.864,0.771,1.421
		c0,0.558-0.258,1.034-0.771,1.433c-0.516,0.396-1.141,0.596-1.873,0.596c-0.734,0-1.362-0.199-1.887-0.596
		c-0.524-0.398-0.787-0.875-0.787-1.433c0-0.557,0.258-1.029,0.772-1.421C51.605,0.343,52.238,0.146,52.99,0.146z"/>
	<path fill={color} d="M99.007,5.678l0.497,5.604h-0.497c-0.661-1.679-1.606-2.886-2.836-3.623
		c-1.228-0.737-2.7-1.106-4.421-1.106c-1.441,0-2.744,0.278-3.908,0.833s-2.08,1.44-2.748,2.656
		c-0.666,1.215-1.001,2.728-1.001,4.534c0,1.49,0.315,2.783,0.945,3.877c0.63,1.095,1.578,1.934,2.844,2.518
		c1.267,0.584,2.71,0.875,4.333,0.875c1.409,0,2.654-0.229,3.732-0.688c1.078-0.457,2.264-1.367,3.557-2.729L100,18.673
		c-1.089,1.468-2.359,2.54-3.813,3.222c-1.453,0.681-3.178,1.021-5.174,1.021c-3.601,0-6.388-1.014-8.363-3.038
		c-1.474-1.508-2.209-3.282-2.209-5.325c0-1.645,0.483-3.156,1.457-4.533c0.972-1.378,2.31-2.445,4.013-3.204
		c1.702-0.758,3.563-1.137,5.583-1.137C93.064,5.678,99.007,5.678,99.007,5.678z"/>
	<path fill={color} d="M45.331,8.024c-1.678-1.475-3.585-2.212-5.721-2.212c-1.172,0-2.305,0.241-3.396,0.723
		c-1.092,0.48-2.369,1.38-3.83,2.695l-0.017-5.329c0-0.291,0.009-0.551,0.025-0.785h-0.01l0.01-2.926h-4L28.39,3.775
		c0,0.042,0.001,0.082,0.001,0.125l0.017,19.66c0,0,7.159,0.073,8.075,0.073c1.095,0,2.153-0.183,3.136-0.399
		c1.828-0.406,3.463-1.189,4.904-2.352c2.216-1.785,3.323-4.098,3.323-6.938C47.848,11.473,47.009,9.499,45.331,8.024z
		 M41.874,20.545c-1.284,1.158-2.826,1.736-4.624,1.736c-1.012,0-1.919-0.182-2.723-0.547c-0.802-0.367-1.361-0.813-1.674-1.336
		s-0.47-1.242-0.47-2.156v-7.859c0.723-0.707,1.453-1.216,2.192-1.526c0.739-0.311,1.582-0.466,2.53-0.466
		c1.847,0,3.424,0.585,4.732,1.756c1.31,1.17,1.964,2.881,1.964,5.136C43.802,17.633,43.159,19.389,41.874,20.545z"/>
	<path fill={color} d="M60.518,21.02c1.678,1.476,3.586,2.211,5.723,2.211c1.171,0,2.304-0.239,3.396-0.721
		c1.092-0.482,2.368-1.381,3.829-2.697l0.019,5.33c0,0.291-0.01,0.551-0.025,0.785h0.01l-0.011,2.926h4l0.002-3.586
		c0-0.043-0.001-0.082-0.001-0.125l-0.018-19.66c0,0-7.159-0.072-8.074-0.072c-1.096,0-2.153,0.182-3.137,0.399
		c-1.828,0.406-3.463,1.189-4.904,2.351c-2.217,1.785-3.323,4.098-3.323,6.938C58.001,17.57,58.84,19.545,60.518,21.02z
		 M63.975,8.499c1.284-1.158,2.826-1.736,4.625-1.736c1.012,0,1.918,0.183,2.723,0.548c0.803,0.366,1.359,0.812,1.674,1.334
		c0.313,0.524,0.47,1.244,0.47,2.157v7.86c-0.723,0.706-1.452,1.215-2.19,1.525c-0.739,0.311-1.584,0.467-2.53,0.467
		c-1.847,0-3.425-0.586-4.731-1.756c-1.311-1.17-1.965-2.881-1.965-5.137C62.047,11.411,62.689,9.656,63.975,8.499z"/>
	<path fill={color} d="M50.966,7.165v4.063c0.015,0.457,0.022,0.98,0.022,1.573v6.453c0,0.274-0.008,0.524-0.022,0.751v3.033
		h4.021v-3.784V7.165H50.966z"/>
	<path fill={color} d="M25.39,0.189h-2.168v5.358v2.534v5.738c0,2.688-0.31,4.596-0.927,5.722
		c-0.619,1.127-1.771,2.051-3.457,2.771c-1.686,0.724-3.597,1.084-5.733,1.084c-1.67,0-3.08-0.23-4.228-0.69
		c-1.148-0.461-2.027-1.045-2.638-1.755c-0.611-0.71-1.032-1.698-1.264-2.969c-0.233-1.27-0.35-2.395-0.35-3.377V6.732V5.548V0.189
		H0v5.358v0.682v7.237c0,3.049,0.257,5.126,0.771,6.234c0.771,1.617,2.08,2.906,3.926,3.863c1.847,0.959,4.553,1.438,8.118,1.438
		c3.276,0,5.865-0.5,7.769-1.502c1.903-1.002,3.18-2.203,3.83-3.604c0.65-1.398,0.975-3.367,0.975-5.906V5.698
		c0-0.052,0.001-0.1,0.001-0.148l0,0V0.189z"/>
</g>
</svg>
  )
}

export default UbiqcLogo