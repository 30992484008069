import React from 'react';
import "./brokerContact.css"

const BrokerContact = () => {
    return (
        <div className='container broker-contact'>
            <h1 className='text-center '>Insurance Companies & Brokers</h1>
            <div className='row custom-padding'>
                <div className='col-lg-6 custom-bg'>
                    <form>
                        <h5 className='text-center'>Join us</h5>
                        <div className="input-group">
                            <label htmlFor="companyName">Company Name</label>
                            <input type="text" name="companyName" />
                        </div>
                        <div className="input-group">
                            <label htmlFor="email">Email</label>
                            <input type="text" name="email" />
                        </div>
                        <div className="input-group">
                            <label htmlFor="companyProfile">Company Website</label>
                            <input type="text" name="companyProfile" />
                        </div>
                        <div className="input-group">
                            <label htmlFor="representativeName">Representative's Name*</label>
                            <input type="text" name="representativeName" required />
                        </div>
                        <div className="input-group">
                            <label htmlFor="representativePhone">Representative's Phone*</label>
                            <input type="text" name="representativePhone" required />
                        </div>
                        <div className="form-btn">
                            <button id="submitJoinUs">Submit</button>
                        </div>
                    </form>
                </div>
                <div className='col-lg-6 img-background'>
                    <div className="enterprises-block img-height">
                        <img src="Assets/Images/Background/grow-with-us.svg" className='img-fluid' alt="grow with us" />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default BrokerContact