import React from 'react';
import "./team.css";
import Data from "../../Data/data";
import { motion } from 'framer-motion'


const Team = () => {
    const [, founders, teams] = Data;

    const container = {
        hidden: { opacity: 1, scale: 0 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.3,
                staggerChildren: 0.2
            }
        }
    };

    const item = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1
        }
    };
    return (
        <motion.div className='container ubiqc-team' id='team' variants={container}
            initial="hidden"
            animate="visible">
            <div className='row ubiqc-cofounder' >
                <h2 className='text-center'>Team</h2>
                {
                    founders.founders.map((founder) => {
                        return (
                            <div className='col-lg-6 card-item' key={founder.id}>
                                <div className="card" >
                                    <img src={founder.image} className="card-img-top rounded-circle mx-auto" alt="..." />
                                    <motion.div className="card-body" variants={item}>
                                        <h5 className="card-title">{founder.name}</h5>
                                        <p className="card-text">{founder.designation}</p>
                                        <p className="card-text">{founder.about}</p>
                                    </motion.div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className='row ubiqc-employee'>
                {
                    teams.teams.map((team) => {
                        return (
                            <div className='col-lg-4 card-item' key={team.id}>
                                <motion.div className="card" variants={item}>
                                    <img src={team.image} className="card-img-top rounded-circle mx-auto" alt="..." />
                                    <div className="card-body">
                                        <p className="card-title">{team.name}</p>
                                        <p className="card-text">{team.designation}</p>

                                    </div>
                                </motion.div>
                            </div>
                        )

                    })
                }
            </div>
        </motion.div>
    )
}

export default Team