import React from 'react';
import "./contactUs.css";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';



const ContacUs = () => {
  const IconStyle = {
    color: "#fff"

  }
  return (
    <div className='container contact-us' id='contact' >
      <h2 className='text-center'>Contact Us</h2>
      <div className='contactUs'>
        <div className='row'>
          <div className='col-lg-6 col-mg-6 col-sm-12'>
            <div className='row bg-phone pt-5'>
              <div className='col-lg-6 col-md-6 col-sm-6'>
                <h5>Phone</h5>
                <p>+91 98 10 501 690</p>
                <p>0120 - 42 09 454</p>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-6'>
                <h5>Business Query</h5>
                <p>
                  business<span>@</span>ubiqc.com
                </p>
                <p>
                  alliances<span>@</span>ubiqc.com
                </p>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-6'>
                <h5>Feedback/Suggestion</h5>
                <p>
                  support<span>@</span>ubiqc.com
                </p>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-6'>
                <h5>Get updated on</h5>
                <p className="smed">
                  <a
                    href="https://www.linkedin.com/company/ubiqc2017/"
                    alt="Linkedin"
                    title="Linkedin"
                    className='p-2'
                    target= 'blank'
                  >
                    <FaLinkedin style={IconStyle} />

                  </a>

                  <a href="https://twitter.com/Ubiqc1"
                   alt="Twitter" 
                   title="Twitter" 
                   className='p-2'
                   target= 'blank'
                   >
                    <FaTwitter style={IconStyle} />


                  </a>
                  <a href="https://www.facebook.com/ubiqc"
                   alt="Facebook" 
                   title="Facebook" 
                   className='p-2'
                   target= 'blank'
                   >
                    <FaFacebook style={IconStyle} />
                  </a>

                  <a href="https://www.instagram.com/ubiqcapp/?hl=en" 
                  alt="Instagram" 
                  title="Instagram" 
                  className='p-2'
                  target= 'blank'
                  >
                    <FaInstagram style={IconStyle} />


                  </a>
                  <a href="https://www.youtube.com/channel/UCkoUjBvPj6cgB88d1r2LHQw" 
                  alt="youtube" 
                  title="Youtube" 
                  className='p-2'
                  target= 'blank'
                  >
                    <FaYoutube style={IconStyle} />


                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 contactus-form pt-5'>
            <form>
              <div className="input-group">
                <label htmlFor="name">Full Name*</label>

                <input type="text" name="name" />
              </div>
              <div className="input-group">
                <label htmlFor="email">Email Address*</label>

                <input type="text" name="email" />
              </div>
              <div className="input-group">
                <label htmlFor="phone">Phone*</label>

                <input type="text" name="phone" />
              </div>
              <div className="input-group">
                <label htmlFor="purpose">Purpose</label>

                <input type="text" name="purpose" />
              </div>
              <input type="text" name="queryType" hidden />
            </form>

            <div className="form-btn">
              <button id="submitContact">
                Submit
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default ContacUs