import React from 'react';
import "./footer.css";
import UbiqcLogo from '../../';

const Footer = () => {
  const LogoColor = "#fff"
  return (
    <div className='ubq-footer container-fluid'>
      <div className='container margin-0-mv'>
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12 text-start pt-3'>
            <p >
              © UBQ Innovations Pvt Ltd. All rights reserved.
              {/* <a href="https://ubiqc.com/"><span><img src="Assets/Images/Logo/ubiqc-logo.svg" alt='' className='footer-logo' color={LogoColor}/></span></a> */}
            </p>
          </div>
          <div className='col-lg-6 col-md-6 text-end footer-link pt-3'>
            <a><span className='display-border'>Terms & Condition</span></a>
            <a><span className='display-border'>Privacy Policy</span></a>
            <a><span>Refund Policy</span></a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer