import React from 'react'
import "./brokerSection.css";
import { motion } from 'framer-motion'

const BrokerSection = () => {
    return (
        <div className='container broker-section'>

            <div className='row'>
                <div className='col-lg-7 col-md-7 col-sm-12 broker-bg'>
                    <div className='broker-para'>

                        <h2 className=''>IT Services <br /> &  <br />White Labelled Solutions</h2>
                        <p className=''>As a startup and leading InsureTech, we understand the needs and trends of the Insurance industry well. We offer custom products and services for Insurance companies, Brokers/Agents etc, aimed at augmenting business, enhancing CX & Customer Retention.</p>

                    </div>
                </div>
                <motion.div className='col-lg-5 col-md-5 col-sm-12 broker-image' initial={{ scale: 0 }}
                    animate={{ rotate: 0, scale: 1 }}
                    transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20
                    }}>
                    <img className='mx-auto' src="Assets/Images/Features/8.png" alt=''/>
                </motion.div>
            </div>
        </div>
    )
}

export default BrokerSection